import { Pipe, PipeTransform } from '@angular/core';


export interface ICommonCheckboxColorStyle {
	borderColor?: string;
	backgroundColor?: string;
}

@Pipe({
	name: 'commonCheckboxColorStyle'
})
export class CommonCheckboxColorStylePipe implements PipeTransform {

	transform (value: boolean, color: string): ICommonCheckboxColorStyle {
		if (!color) {
			return {};
		}

		const hexColor = '#' + color;

		return {
			borderColor: !value ? hexColor : null,
			backgroundColor: value ? hexColor : null,
		};
	}

}

