import { Injectable } from '@angular/core';

import { extend } from 'lodash';

import { CommonDialogFactoryService } from '../../../Common/dialog-factory/services/dialog-factory.service';
import { ICommonDialogFactoryOptions } from '../../../Common/dialog-factory/interfaces/dialog-factory-options.interface';
import { CommonPopup } from '../../../Common/popup/popup';
import { CommonLocaleService } from '../../../Common/locale/locale.service';

type AppSaveChangesActiveListener = (callback: () => void) => void | null;

@Injectable({
	providedIn: 'root',
})
export class AppSaveChangesService {
	private activeListener: AppSaveChangesActiveListener;

	constructor (
		private dialogFactory: CommonDialogFactoryService,
		private localeService: CommonLocaleService,
	) {}

	showConfirmationDialog (popupConfig: Partial<ICommonDialogFactoryOptions>): CommonPopup {
		const config = extend({
			header: this.localeService.instant('common.save_changes_dialog.header'),
			declineText:  this.localeService.instant('common.save_changes_dialog.decline_text'),
			closeText:  this.localeService.instant('common.save_changes_dialog.close_text'),
			acceptText:  this.localeService.instant('common.save_changes_dialog.accept_text.save'),
		}, popupConfig);

		return this.dialogFactory.create(config);
	}

	callTrusted (callback: () => any) {
		!this.activeListener ? callback() : this.activeListener(callback);
	}

	toggleActiveListener (listener: AppSaveChangesActiveListener) {
		this.activeListener = listener;
	}
}
