import { ILogger } from '@microsoft/signalr/src/ILogger';
import { LogLevel } from '@microsoft/signalr';

// Copy from signalr ConsoleLogger
export class CommonConsoleLogger implements ILogger {
	private readonly minimumLogLevel: LogLevel;

	// Public for testing purposes.
	public outputConsole: {
		error(message: any): void,
		warn(message: any): void,
		info(message: any): void,
		log(message: any): void,
	};

	constructor(minimumLogLevel: LogLevel) {
		this.minimumLogLevel = minimumLogLevel;
		this.outputConsole = console;
	}

	public log(logLevel: LogLevel, message: string): void {
		if (message === 'Failed to start the HttpConnection before stop() was called.') {
			return; // ABORT
		}

		if (logLevel >= this.minimumLogLevel) {
			switch (logLevel) {
				case LogLevel.Critical:
				case LogLevel.Error:
					this.outputConsole.error(`[${new Date().toISOString()}] ${LogLevel[logLevel]}: ${message}`);
					break;
				case LogLevel.Warning:
					this.outputConsole.warn(`[${new Date().toISOString()}] ${LogLevel[logLevel]}: ${message}`);
					break;
				case LogLevel.Information:
					this.outputConsole.info(`[${new Date().toISOString()}] ${LogLevel[logLevel]}: ${message}`);
					break;
				default:
					// console.debug only goes to attached debuggers in Node, so we use console.log for Trace and Debug
					this.outputConsole.log(`[${new Date().toISOString()}] ${LogLevel[logLevel]}: ${message}`);
					break;
			}
		}
	}
}
