import { BehaviorSubject, Subject } from 'rxjs';
import { add as unload } from 'unload';
import { keys, filter, map, sortBy, find, cloneDeep, extend } from 'lodash';
import { timeoutByIndexBD } from '@CaseOne/Common/utilities/timeout-without-setTimeout';
import './local-store-sync-ie11-fixer';


export interface IStatusData {
	id: string,
	start: number,
	check: number,
	activeTab: boolean,
	isLeader: boolean,
	freezeTime: number,
	customData: any,
}

const LEADER_LIFESPAN = 500; // ms
const LEADER_NAME_KEY = 'case_one_tabs_leader_';

const startTime = Date.now();
const tabId = LEADER_NAME_KEY + startTime + '_' + Math.random();
export const iAmLeader$ = new BehaviorSubject<boolean>(false);
export const customDataFromAllTabs$ = new Subject<IStatusData[]>();

let isRun = false;
let myStatusData: IStatusData = {
	id: tabId,
	start: startTime,
	check: Date.now(),
	activeTab: document.visibilityState === 'visible',
	isLeader: false,
	freezeTime: 0,
	customData: null,
};
let customData;

unload(() => {
	localStorage.removeItem(tabId); // Proud King
});

export function startCheckingLeader () {
	if (!isRun) {
		isRun = true;

		document.addEventListener('visibilitychange', () => {
			updateMyStatus(myStatusData.freezeTime);
			checkLeader(getLeaders());
		});

		runCheck(Math.round(performance.now()));
	}
}

export function setCustomData (data: any) {
	if (!customData) {
		customData = {};
	}

	customData = extend(customData, data);
}

function runCheck (routineStartTime: number) {
	const now = Math.round(performance.now());
	const freezeTime = Math.max(now - routineStartTime - LEADER_LIFESPAN, 0);

	updateMyStatus(freezeTime);

	const leaders = getLeaders();
	clearDeadTabs(leaders, freezeTime);
	checkLeader(leaders);
	customDataFromAllTabs$.next(cloneDeep(leaders));

	if (myStatusData.isLeader) {
		// Force mode for leader
		// Protects from inactive tab
		timeoutByIndexBD(() => runCheck(now), LEADER_LIFESPAN);
	} else {
		setTimeout(() => runCheck(now), LEADER_LIFESPAN);
	}
}

function updateMyStatus (freezeTime: number) {
	myStatusData = {
		...myStatusData,
		check: Date.now(),
		activeTab: document.visibilityState === 'visible',
		freezeTime,
		customData,
	};

	localStorage.setItem(tabId, JSON.stringify(myStatusData));
}

export function getLeaders () {
	const allItems = keys(localStorage);
	const leaderKeys = filter(allItems, (key) => key.indexOf(LEADER_NAME_KEY) === 0);
	return map(leaderKeys, (k) => JSON.parse(localStorage[k]));
}

function clearDeadTabs (leaders: IStatusData[], freezeTime: number) {
	leaders.forEach((leader) => {
		if ((LEADER_LIFESPAN + freezeTime + leader.freezeTime) * 2 < Date.now() - leader.check) { // Old King - Dead King
			localStorage.removeItem(leader.id);
		}
	});
}

export function getMainLeader (leaders: IStatusData[]): IStatusData {
	const activeTabs = filter(leaders, (l) => l.activeTab);
	const firstActiveTab = sortBy(activeTabs, 'start')[0];

	if (firstActiveTab) {
		return firstActiveTab;

	} else {
		const oldLeader = find(leaders, { isLeader: true });

		if (oldLeader) {
			return oldLeader;

		} else {
			return sortBy(leaders, 'start')[0]; // Kind of Kings
		}
	}
}

function checkLeader (leaders: IStatusData[]) {
	const mainLeader = getMainLeader(leaders);
	const iAmMainLeader = mainLeader.id === tabId;

	if (iAmLeader$.getValue() !== iAmMainLeader) {
		myStatusData.isLeader = true;
		localStorage.setItem(tabId, JSON.stringify(myStatusData));

		leaders.forEach((leader) => {
			if (leader.isLeader && leader.id !== tabId) {
				leader.isLeader = false;
				localStorage.setItem(leader.id, JSON.stringify(leader));
			}
		});

		iAmLeader$.next(iAmMainLeader); // Le Roi est mort, vive le Roi!
	}
}
