import { NgModule } from '@angular/core';
import { CommonPopupModule } from '../popup/popup.module';
import { CommonDialogModule } from '../blocks/dialog/common-dialog.module';
import { CommonDialogFactoryService } from './services/dialog-factory.service';
import { CommonDialogFactoryComponent } from './components/dialog-factory.component';

@NgModule({
	imports: [
		CommonPopupModule,
		CommonDialogModule,
	],
	providers: [
		CommonDialogFactoryService,
	],
	declarations: [
		CommonDialogFactoryComponent,
	],
	entryComponents: [
		CommonDialogFactoryComponent,
	],
})
export class CommonDialogFactoryModule {}
