import {
	AbstractControl,
	NG_VALIDATORS,
	ValidationErrors,
	Validator,
} from '@angular/forms';
import {
	Directive,
	Input,
} from '@angular/core';

export const COMMON_CHOOSE_TRUE_ERROR_KEY = 'CommonChooseTrueValidator';

@Directive({
	selector: '[commonChooseTrueValidatorDirective]',
	providers: [{
		provide: NG_VALIDATORS,
		useExisting: CommonChooseTrueValidatorDirective,
		multi: true,
	}],
})
export class CommonChooseTrueValidatorDirective implements Validator {
	@Input('commonChooseTrueValidatorDirective') enabled: boolean;

	validate(control: AbstractControl): ValidationErrors | null {
		if (this.enabled === false || control.value === true) {
			return null;
		} else {
			return {
				[COMMON_CHOOSE_TRUE_ERROR_KEY]: true,
			};
		}
	}
}
