import {
	Component,
} from '@angular/core';
import { CommonBaseComponent } from '../../../Common/base_component/base.component';
import { CommonEscapedBracketReplace } from '@CaseOne/Common/pipes/escape_bracket_replace.filter';
import { CommonAppDataService } from '@CaseOne/Common/app_data/app_data.service';
import {
	AuthenticationConsentService
} from '@CaseOne/Authentication/src/authentication/services/authentication-consent.service';
import { AppProfileService } from '@CaseOne/App/src/profile/shared/profile-service/services/app-profile.service';
import {
	AuthenticationLoginService
} from '@CaseOne/Authentication/src/authentication/services/authentication-login.service';

@Component({
	selector: 'authentication-consent',
	templateUrl: './consent.component.pug',
})
export class AuthenticationConsentComponent extends CommonBaseComponent {
	private commonAppDataService = this.injector.get(CommonAppDataService);
	private authenticationLoginService = this.injector.get(AuthenticationLoginService);
	private appProfileService = this.injector.get(AppProfileService);
	private authenticationConsentService = this.injector.get(AuthenticationConsentService);

	public consentTermsText: string;
	public isAgreeWithTermsOfPersonalData: boolean = false;

	ngOnInit() {
		super.ngOnInit();
		this.consentTermsText = new CommonEscapedBracketReplace().transform(
			this.instant('authentication.forms.consent.terms_link.text', {
				consentTermsLink: this.getConsentTermsLink(),
			}),
		);
	}

	public getConsentTermsLink(): string {
		const consentTermsHref = this.commonAppDataService.getDataByPath('TermsOfPersonalDataLink');

		return `<a target="_blank" href="${consentTermsHref}" class="b-authentication-form-link">${this.instant('authentication.forms.consent.terms_link.link')}</a>`;
	}

	public sendConsentData(): void {
		this.authenticationConsentService.agreeWithTermsOfPersonalData()
			.pipe(this.takeUntilDestroy())
			.subscribe(() => this.authenticationLoginService.redirect(this.authenticationLoginService.getReturnUrl()));
	}

	public returnToLogin(): void {
		this.appProfileService.logoff();
	}
}
