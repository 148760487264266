import { Injectable } from '@angular/core';
import { get, clone, merge, compact } from 'lodash';

import { commonConstants } from '../../constants/common.constants';
import { CommonLocaleService } from '../../locale/locale.service';
import { CommonServerDateService } from '../../server_date/server_date.service';
import { CommonGeneralSettingsService } from '../../general_settings/general_settings.service';

import * as Inputmask from 'inputmask';
import { combineLatest, BehaviorSubject } from 'rxjs';
import { COMMON_MAX_COUNT_ZEROS } from '@CaseOne/Common/dictionaries/common-count-zeros.dictionary.service';


declare interface IMask {
	mask: string;
	placeholder?: string;
	showMaskOnHover?: boolean;
	showMaskOnFocus?: boolean;
	hourFormat?: string;
	alias?: string;
	leapday?: string;
	separator?: string;
	yearrange?: {}
}

interface ICommonTextfieldInputMaskSettings {
	alias: string;
	radixPoint: string;
	groupSeparator: string;
	autoGroup: boolean;
	rightAlign: boolean;
	unmaskAsNumber: boolean;
	digits: number;
	allowMinus: boolean;
}

const { defaultNumberDecimalSeparator, defaultNumberGroupSeparator, defaultNumberDigitsAfterDecimal } = commonConstants;

@Injectable()
export class CommonTextfieldInputMaskAliasesService {
	public decimalSeparator: string;
	public groupSeparator: string;

	private saveCharsRegExp = new RegExp('[^\\\\:/*?"<>|]');
	private tagCharsRegExp = new RegExp('^[0-9A-Za-z_]');
	private externalTagCharsRegExp = new RegExp('^[0-9A-Za-z_\\[\\]]');
	private domainRegExp = new RegExp('^[0-9a-zA-Z-]');
	private scriptNameRegExp = new RegExp('^[0-9a-zA-Z-_]');
	private onlyLettersRegExp = new RegExp(/(^$)|(^([^\-!#\$%&\(\)\*,\./:;\?@\[\\\]_\{\|\}¨ˇ“”€\+<=>§°\d\s¤®™©'"^±~`№])+$)/); // all letters

	private numericParams: ICommonTextfieldInputMaskSettings;
	private numericParamsMaxDigest: ICommonTextfieldInputMaskSettings;
	private integerParams: ICommonTextfieldInputMaskSettings;
	private integerPositiveParams: ICommonTextfieldInputMaskSettings;
	private numericParamsNoMinus: ICommonTextfieldInputMaskSettings;

	private init$ = new BehaviorSubject<boolean>(false);

	constructor(
		private commonGeneralSettingsService: CommonGeneralSettingsService,
		private commonServerDateService: CommonServerDateService,
		private commonLocaleService: CommonLocaleService,
	) {}

	init (): Promise<boolean> {
		return new Promise((resolve) => {  // for APP_INITIALIZER
			const res = combineLatest([
				this.commonGeneralSettingsService.wait(),
				this.commonServerDateService.wait(),
				this.commonLocaleService.wait(),
			]);

			res.subscribe((result) => {
				if (result.length === compact(result).length) {
					this.setAliases();
					this.init$.next(true);
					resolve(true);
				}
			});
		});
	}

	wait(): BehaviorSubject<boolean> {
		return this.init$;
	}

	setAliases (): void {
		const numberFormat = this.commonGeneralSettingsService.getGeneralSettings('NumberFormat');

		this.decimalSeparator = get(numberFormat, 'DecimalSeparator') || defaultNumberDecimalSeparator;
		this.groupSeparator = get(numberFormat, 'GroupSeparator') || defaultNumberGroupSeparator;
		this.numericParams = {
			alias: 'numeric',
			radixPoint: this.decimalSeparator,
			groupSeparator: this.groupSeparator,
			autoGroup: true,
			rightAlign: false,
			unmaskAsNumber: true,
			digits: defaultNumberDigitsAfterDecimal,
			allowMinus: true,
		};

		this.numericParamsMaxDigest = clone(this.numericParams);
		this.numericParamsMaxDigest.digits = COMMON_MAX_COUNT_ZEROS;

		this.integerParams = clone(this.numericParams);
		this.integerParams.alias = 'integer';
		this.integerParams.digits = 0;

		this.integerPositiveParams = clone(this.integerParams);
		this.integerPositiveParams.allowMinus = false;

		this.numericParamsNoMinus = clone(this.numericParams);
		this.numericParamsNoMinus.allowMinus = false;

		Inputmask
			.extendAliases({
				// text mask with only digits ("00001")
				'casem-text-digits': {
					mask: 'b',
					repeat: '*',
					greedy: false,
					autoUnmask: true,
					showMaskOnHover: false,
					showMaskOnFocus: false,
					isComplete: (buffer: []): boolean => {
						return commonConstants.dataValidation.digits.test(buffer.join(''));
					},
					definitions: {
						b: {
							validator: (chr): boolean => {
								return commonConstants.dataValidation.digits.test(chr);
							},
						},
					},
				},
				'casem-phone': {
					mask: '\\+9{*}',
					showMaskOnHover: false,
					showMaskOnFocus: true,
				},
				'casem-phone-optional-plus': {
					mask: '(9{*})|(\\+9{*})',
					showMaskOnHover: false,
					showMaskOnFocus: true,
				},
				'casem-safe-characters': {
					mask: 'c',
					greedy: false,
					repeat: '*', // http://git.io/vcguC - transmits value to model
					isComplete: (buffer: []): boolean => {
						// Logic broadcast 'isComplete' for model
						return this.saveCharsRegExp.test(buffer.join(''));
					},
					definitions: {
						c: {
							validator: (chr: string): boolean => {
								return this.saveCharsRegExp.test(chr);
							},
						},
					},
				},
				'casem-tag': {
					mask: 'd',
					greedy: false,
					repeat: '*', // http://git.io/vcguC - transmits value to model
					isComplete: (buffer: []): boolean => {
						// Logic broadcast 'isComplete' for model
						return this.tagCharsRegExp.test(buffer.join(''));
					},
					definitions: {
						d: {
							validator: (chr: string): boolean => {
								return this.tagCharsRegExp.test(chr);
							},
						},
					},
				},
				'casem-external-tag': {
					mask: 'e',
					greedy: false,
					repeat: '+', // http://git.io/vcguC - transmits value to model
					isComplete: (buffer: []): boolean => {
						// Logic broadcast 'isComplete' for model
						return this.externalTagCharsRegExp.test(buffer.join(''));
					},
					definitions: {
						e: {
							validator: (chr: string): boolean => {
								return this.externalTagCharsRegExp.test(chr);
							},
						},
					},
				},
				'casem-domain': {
					mask: 'f',
					greedy: false,
					repeat: '*', // http://git.io/vcguC - transmits value to model
					isComplete: (buffer: []) => {
						// Logic broadcast 'isComplete' for model
						return this.domainRegExp.test(buffer.join(''));
					},
					definitions: {
						f: {
							validator: (chr: string): boolean => {
								return this.domainRegExp.test(chr);
							},
						},
					},
				},
				'casem-script-name': {
					mask: '*',
					greedy: false,
					repeat: '*', // http://git.io/vcguC - transmits value to model
					isComplete: (buffer: []) => {
						// Logic broadcast 'isComplete' for model
						return this.scriptNameRegExp.test(buffer.join(''));
					},
					definitions: {
						'*': {
							validator: (chr: string): boolean => {
								return this.scriptNameRegExp.test(chr);
							},
						},
					},
				},
				'casem-numeric': this.numericParams,
				'casem-numeric-max_digits': this.numericParamsMaxDigest,
				'casem-numeric-no-minus': this.numericParamsNoMinus,
				'casem-integer': this.integerParams,
				'casem-integer-positive': this.integerPositiveParams,
				'casem-letters': {
					mask: '*',
					greedy: false,
					repeat: '+',
					isComplete: (buffer: []) => {
						return this.onlyLettersRegExp.test(buffer.join(''));
					},
					definitions: {
						'*': {
							validator: (chr: string): boolean => {
								return this.onlyLettersRegExp.test(chr);
							},
						},
					},
				},
				'casem-cardnumber': {
					mask: 'i',
					repeat: '24',
					greedy: false,
					autoUnmask: true,
					showMaskOnHover: false,
					showMaskOnFocus: false,
					isComplete: (buffer: []): boolean => {
						return commonConstants.dataValidation.cardNumber.test(buffer.join(''));
					},
					definitions: {
						i: {
							validator: (chr: string): boolean => {
								return commonConstants.dataValidation.cardNumber.test(chr);
							},
						},
					},
				},
				'casem-invoice-time-rounding-minutes': {
					alias: 'numeric',
					repeat: '3',
					greedy: false,
					rightAlign: false,
					unmaskAsNumber: true,
					allowMinus: false,
					digits: 0,
				},
				'casem-invoice-time-rounding-hours': {
					alias: 'numeric',
					repeat: '2',
					greedy: false,
					rightAlign: false,
					unmaskAsNumber: true,
					allowMinus: false,
					digits: 2,
				},
				'casem-time': this.getCasemTimeMask(),
				'casem-date': this.getCasemDateMask(),
				'casem-cardexpire': this.getCasemCardExpireMask(),
				'casem-cardexpire-month': this.getCasemCardExpireMonthMask(),
				'casem-cardexpire-year': this.getCasemCardExpireYearhMask(),
				'casem-participant-individual-inn': this.getCasemParticipantIndividualInnMask(),
				'casem-resolution-number': {
					mask: '9{1,10}',
					placeholder: '',
				},
			});
	}

	private getCasemDateMask (): IMask {
		const inputDateMask = this.commonServerDateService.inputDateMask;
		const mask = inputDateMask.replace(/dd|mm/, '1').replace(/dd|mm/, '2').replace('yyyy', 'y');
		const alias = inputDateMask.replace(/\W/g, '/');
		const leapday = inputDateMask.replace('dd', '29').replace('mm', '02').replace('yyyy', '');
		const separator = inputDateMask.match(/\W/)[0];

		return {
			mask,
			placeholder: this.commonServerDateService.inputDatePlaceholder,
			leapday,
			separator,
			alias,
			yearrange: {
				minyear: 1,
				maxyear: 2999,
			},
			showMaskOnHover: false,
			showMaskOnFocus: true,
		};
	}

	private getCasemTimeMask (): IMask {
		return {
			mask: this.commonServerDateService.inputTimeMask,
			placeholder: this.commonServerDateService.inputTimePlaceholder,
			alias: 'datetime',
			hourFormat: this.commonGeneralSettingsService.hasMeridiem() ? '12' : '24',
			showMaskOnHover: false,
			showMaskOnFocus: true,
		};
	}

	private getCasemCardExpireMask (): IMask {
		const monthsSymbol = this.commonLocaleService.instant('common.symbols.months');
		const yearsSymbol = this.commonLocaleService.instant('common.symbols.years');

		return {
			mask: '99/99',
			placeholder: (monthsSymbol + monthsSymbol + '/' + yearsSymbol + yearsSymbol).toUpperCase(),
			showMaskOnHover: false,
			showMaskOnFocus: false,
		};
	}

	private getCasemCardExpireMonthMask (): IMask {
		const monthsSymbol = this.commonLocaleService.instant('common.symbols.months');

		return {
			mask: '99',
			placeholder: (monthsSymbol + monthsSymbol).toUpperCase(),
			showMaskOnHover: false,
			showMaskOnFocus: false,
		};
	}

	private getCasemCardExpireYearhMask (): IMask {
		const yearsSymbol = this.commonLocaleService.instant('common.symbols.years');

		return {
			mask: '(99)|(9999)',
			placeholder: (yearsSymbol + yearsSymbol).toUpperCase(),
			showMaskOnHover: false,
			showMaskOnFocus: false,
		};
	}

	private getCasemParticipantIndividualInnMask (): IMask {
		const locale = this.commonLocaleService.locale;

		const masks = {
			ru: {
				mask: '999999999999',
			},
			en: {
				mask: '999-99-9999',
			},
			fr: {
				mask: '9 99 99 99 999 999 99',
			},
			pt: {
				mask: '999-99-9999',
			},
		};

		const maskParams = {
			autoUnmask: true,
		};

		return merge(masks[locale], maskParams);
	}
}
