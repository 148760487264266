const conf = {
	sourcemaps: false,
	devspecs: false,
	parallelTests: true,
	production: false,
	reloadless: false,
	buildConfig: {
		language: 'en',
		locale: 'en-US',
		server: '',
		login: '',
		password: '',
		host: 'localhost',
		port: 9000,
		https: true,
		proxyTargets: ['/api', '/authentication', '/Outlook', '/account'],
		proxyWSTargets: ['/installHub', '/objectHub'],
	},
	userData: {},
};

let devConf = {};

try {
	devConf = require('./dev.conf');
} catch (e) {
	console.warn('Warning: dev.conf.js not found. Please create it for development.\n');
}

module.exports = {
	...conf,
	...devConf,
	buildConfig: {
		...conf.buildConfig,
		...devConf.buildConfig
	},
};

