import { remove, includes } from 'lodash';


interface ITask {
	id: string,
	action: () => void,
	start: number,
	time: number,
}

const timersId = 'timer-target';
const openRequest = indexedDB.open('timeout-db', 1);
const tasks: ITask[] = [];

let isInit = false;
let isActiveCheck = false;

openRequest.onerror = () => {
	console.error('Error', openRequest.error);
};

openRequest.onupgradeneeded = () => {
	const db = openRequest.result;
	db.createObjectStore('timers', { keyPath: 'id' });
};

openRequest.onsuccess = () => {
	isInit = true;
	checkTasks();
};

function checkTasks () {
	isActiveCheck = true;

	if (tasks.length) {
		const finished = [];

		tasks.forEach((task) => {
			if (task.start + task.time < Date.now()) {
				try {
					task.action();
				} catch (e) {
					console.error(e);
				}

				finished.push(task.id);
			}
		});

		remove(tasks, (t) => includes(finished, t.id));

		const db = openRequest.result;
		const transaction = db.transaction('timers', 'readwrite');
		const timersTable = transaction.objectStore('timers');
		const putRequest = timersTable.put({
			id: timersId,
		});

		putRequest.onsuccess = () => {
			checkTasks();
		};
	} else {

		isActiveCheck = false;
	}
}

/**
 * @deprecated
 * Not deprecated
 * Not to be used anywhere else
 *
 * Work in inactive tabs
 */
export function timeoutByIndexBD (func: () => void, time = 0): string {
	const newTask = {
		id: Date.now() + '_' + Math.random(),
		action: func,
		start: Date.now(),
		time,
	};

	tasks.push(newTask);

	if (isInit && !isActiveCheck) {
		checkTasks();
	}

	return newTask.id;
}

export function cancelTask (id: string) {
	remove(tasks, (t) => t.id === id);
}
