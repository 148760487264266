import { Component, Inject, Injector } from '@angular/core';

import { CommonBaseComponent } from '../../base_component/base.component';
import { COMMON_POPUP_DATA } from '../../popup/constants/popup-data.constant';
import { ICommonDialogFactoryComponentData } from '../interfaces/dialog-factory-data-component.interface';

@Component({
	templateUrl: './dialog-factory.component.pug',
})
export class CommonDialogFactoryComponent extends CommonBaseComponent {
	constructor (
		@Inject(COMMON_POPUP_DATA) public data: ICommonDialogFactoryComponentData,
		protected injector: Injector,
	) {
		super(injector);
	}
}
