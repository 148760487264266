import { NgModule } from '@angular/core';

import { CommonDialogFactoryModule } from '../../../Common/dialog-factory/dialog-factory.module';
import { CommonLocaleModule } from '../../../Common/locale/locale.module';

import { AppSaveChangesService } from './app-save-changes.service';
import { AppSaveChangesDialogComponent } from './components/app-save-changes-dialog.component';

@NgModule({
	imports: [
		CommonLocaleModule,
		CommonDialogFactoryModule,
	],
	providers: [
		AppSaveChangesService,
	],
	declarations: [
		AppSaveChangesDialogComponent,
	],
	exports: [
		AppSaveChangesDialogComponent,
	],
	entryComponents: [
		AppSaveChangesDialogComponent,
	],
})
export class AppSaveChangesModule {}
