import {
	HttpTransportType,
	HubConnection,
	HubConnectionBuilder,
	HubConnectionState,
	LogLevel, // Need for debugging
} from '@microsoft/signalr';
import { COMMON_WEB_SOCKET_CONNECTION_PATH } from './web-socket-connection-path.constant';
import { Injectable } from '@angular/core';
import { CommonLocaleService } from '../locale/locale.service';
import {
	COMMON_PAGE_VISIBILITY_EVENT,
	CommonPageVisibilityService,
} from '../page-visibility/common-page-visibility.service';
import { CommonWindowService } from '@CaseOne/Common/window/services/common-window.service';
import { CommonEventsService } from '@CaseOne/Common/events/services/events.service';
import { Subscription } from 'rxjs';
import { COMMON_WEB_SOCKET_CONNECTION_MSG } from '@CaseOne/Common/web-socket-connection.service/web-socket-connection-msg.constant';
import { filter } from 'rxjs/operators';
import { CommonAppDataService } from '@CaseOne/Common/app_data/app_data.service';
import './../utilities/local-store-sync-ie11-fixer';
import { remove, forEach, find } from 'lodash';
import { CommonConsoleLogger } from '@CaseOne/Common/web-socket-connection.service/common-console-logger.class';


interface ICommonWebSocketEvent {
	name: string,
	callback: (argOne: any, argTwo: any) => void,
	subscriber: Subscription,
}


// Tabs session in one browser
const SOCKET_SESSION_ID_KEY = 'case_one_socket_session_id';

@Injectable({
	providedIn: 'root',
})
export class CommonWebSocketConnection {
	protected connection: HubConnection;
	private isStarted: boolean = false;  // if the service received a command to start
	private canBeConnected: boolean = false;  // if there was at least one successful connection attempt
	private readonly isWebSocketsEnabled: boolean = !!this.commonAppDataService.getData().IsWebSocketsEnabled;
	private customParams = '';
	private startingPromise: Promise<any>;
	private events: ICommonWebSocketEvent[] = [];

	constructor(
		private commonLocaleService: CommonLocaleService,
		private commonPageVisibilityService: CommonPageVisibilityService,
		private commonWindowService: CommonWindowService,
		private commonEventsService: CommonEventsService,
		private commonAppDataService: CommonAppDataService,
	) {}

	private createConnection() {
		let sessionId = localStorage.getItem(SOCKET_SESSION_ID_KEY);

		if (!sessionId) {
			sessionId = Date.now() + '_' + Math.random();
			localStorage.setItem(SOCKET_SESSION_ID_KEY, sessionId);
		}

		this.connection = new HubConnectionBuilder()
			.withUrl(COMMON_WEB_SOCKET_CONNECTION_PATH + '?session_id=' + sessionId + this.customParams, {
				skipNegotiation: this.isWebSocketsEnabled,
				transport: this.isWebSocketsEnabled ? HttpTransportType.WebSockets : HttpTransportType.LongPolling,
				logger: new CommonConsoleLogger(LogLevel.Error),
				// logger: LogLevel.Trace, // Need for debugging
			})
			.withAutomaticReconnect({
				nextRetryDelayInMilliseconds: (retryContext) => {
					if (this.isWebSocketsEnabled) {
						return this.tryReconnect(retryContext);

					} else {
						if (this.commonWindowService.iAmLeader) {
							return this.tryReconnect(retryContext);

						} else {
							return null;
						}
					}
				},
			})
			.build();

		this.connection.onreconnected(() => {
			this.canBeConnected = true;
		});

		this.commonPageVisibilityService
			.on(COMMON_PAGE_VISIBILITY_EVENT.CHANGE_VISIBILITY)
			.subscribe((isVisible) => {
				if (
					this.isStarted
					&& isVisible
					&& this.connection && this.connection.state === HubConnectionState.Disconnected
				) {
					this.start();
				}
			});

		forEach(this.events, (event) => {
			this.connectionOn(event.name);
		});
	}

	private tryReconnect (retryContext): number {
		if (retryContext.elapsedMilliseconds < 60000) {

			// If we've been reconnecting for less than 60 seconds so far,
			// wait between 0 and 10 seconds before the next reconnect attempt.
			return Math.random() * 10000;
		} else {

			// If we've been reconnecting for more than 60 seconds so far, stop reconnecting.
			this.showConnectionError();
			return null;
		}
	}

	async setCustomParams (newParams: string = '') {
		if (newParams !== this.customParams) {
			this.customParams = newParams;
			await this.start();
		}
	}

	async start (): Promise<void> {
		this.stop(); // Ignore promise
		this.createConnection();

		try {
			this.startingPromise = this.connection
				.start()
				.finally(() => {
					this.startingPromise = null;
				})
				.then(() => {
					this.canBeConnected = true;
				});

			await this.startingPromise;
		} catch (error) {
			// Ignore fast reconnection error
			if (error.message !== 'Failed to start the HttpConnection before stop() was called.') {
				this.showConnectionError();
			}
		}
	}

	async stop (): Promise<void> {
		if (this.connection) {
			try {
				const promise = this.connection.stop();
				this.connection = null;
				await promise;
			} catch (error) {
				console.error(error);
			}
		}
	}

	on (eventName: string, callback: (argOne: any, argTwo: any) => void): () => void {
		if (this.connection && !this.eventIsRegistered(eventName)) {
			this.connectionOn(eventName);
		}

		const subscriber = this.commonEventsService
			.on(COMMON_WEB_SOCKET_CONNECTION_MSG)
			.pipe(
				filter((event) => event.name === eventName),
			)
			.subscribe((payload) => {
				callback.apply(null, payload.payload);
			});

		const newEvent: ICommonWebSocketEvent = {
			name: eventName,
			callback,
			subscriber,
		};

		this.events.push(newEvent);

		return () => {
			remove(this.events, (event) => {
				return event.name === eventName && event.callback === callback;
			});
			newEvent.subscriber.unsubscribe();

			if (!this.eventIsRegistered(eventName)) {
				this.connection.off(newEvent.name);
			}
		};
	}

	private showConnectionError () {
		if (this.commonPageVisibilityService.isVisible && !this.canBeConnected) {
			console.warn(
				this.commonLocaleService.instant('common.noty.web_socket_connection_error.title') + ': '
				+ this.commonLocaleService.instant('common.noty.web_socket_connection_error.text'),
			);
		}
	}

	private connectionOn (eventName: string) {
		this.connection.on(eventName, (...args) => {
			const event = {
				name: COMMON_WEB_SOCKET_CONNECTION_MSG,
				payload: {
					name: eventName,
					payload: args,
				},
			};

			if (!this.isWebSocketsEnabled) {
				this.commonWindowService.postMessage(event);
			}

			this.commonEventsService.emit(event); // for current leader tab
		});
	}

	private eventIsRegistered(eventName: string): boolean {
		const targetEvent = find(this.events, {name: eventName});

		return Boolean(targetEvent);
	}
}
