import { Injectable, Injector } from '@angular/core';
import { CommonBaseService } from '@CaseOne/Common/common-base-service.class/common-base-service.class';
import { UIRouter } from '@uirouter/angular';
import { CommonAppDataService } from '@CaseOne/Common/app_data/app_data.service';

@Injectable()
export class AuthenticationLoginService extends CommonBaseService {
	constructor(
		public injector: Injector,
		private uiRouter: UIRouter,
		private commonAppDataService: CommonAppDataService,

	) {
		super(injector);
	}

	public redirect(url: string): void {
		const isLocalUrl = location.href.split('#')[0] === url.split('#')[0];

		url = url.replace(/&amp;/g, '&');  // back-end add &amp; in url

		// Disabled routing for defender not allowed url
		this.uiRouter.dispose();
		window.location.assign(url);

		// Run redirect for local url
		if (isLocalUrl) {
			window.location.reload();
		}
	}

	public getReturnUrl(): string {
		let returnUrl = this.commonAppDataService.getDataByPath('authenticationReturnUrl');

		// we haven't the hash in ReturnUrl, when user redirected by back-end
		// we save the current hash in authenticationReturnUrlHash before the auth application is initialized
		if (returnUrl && !returnUrl.includes('#')) {
			returnUrl += this.commonAppDataService.getDataByPath('authenticationReturnUrlHash');
		}

		return returnUrl || this.commonAppDataService.getDataByPath('appFullLocationUrl');
	}
}
