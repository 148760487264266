<span class="{{ cssClasses }}" [ngClass]="{
		'b-checkbox': true,
		'b-control': true,
		'is-disabled': isDisabled,

		'b-common_control': true,
		'b-common_control--empty': false,
		'b-common_control--hovered': isHovered,
		'b-common_control--focused': isFocused,
		'b-common_control--pressed': isPressed,
		'b-common_control--disabled': isDisabled,
		'b-common_control--invalid': !isValid,

		'b-common_checkbox': true,
		'b-common_checkbox--checked': model,
		'b-common_checkbox--indeterminate': isIndeterminate,
		'b-common_checkbox--with_label': label || htmlLabel,
		'b-common_checkbox--color': !!color
	}" (mouseenter)="onMouseEnterHandler()" (mouseleave)="onMouseLeaveHandler()" (mousedown)="onMouseDownHandler()" (mouseup)="onMouseUpHandler()"><span class="b-checkbox-img" [ngStyle]="model | commonCheckboxColorStyle:color"></span><input class="b-checkbox-input" #input type="checkbox" id="{{ checkboxId || id }}" [name]="name || id" [checked]="model" [disabled]="isDisabled" [title]="label || ''" (change)="onChangeCheckbox(input.checked)" (focus)="onFocusHandler($event)" (blur)="onBlurHandler($event)"><label class="b-checkbox-label {{ labelClasses }}" [class.draggable]="isDraggable" for="{{ checkboxId || id }}" [title]="label || ''"><span *ngIf="label">{{ label }}</span><!-- CASEM-52759 `label || ""` for IE11--><span *ngIf="htmlLabel" [innerHTML]="htmlLabel || ''"></span><div class="b-checkbox-description" *ngIf="isDescriptionShow">{{ descriptionValue }}</div></label></span>