// New version src/Common/controls/checkbox/checkbox.component.ts CommonCheckboxComponent
// Old version src/CaseDotStar.ServicePackages.Frontend.Common/scripts/common/controls/ctrl_checkbox_directive.js CtrlCheckbox

import {
	ChangeDetectionStrategy,
	Component,
	forwardRef,
	Input,
	SimpleChanges,
	ViewEncapsulation,
} from '@angular/core';
import { CommonDebugComponent } from '../control/debug/debug.decorator';
import { CommonControlComponent } from '../control/control.class';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@CommonDebugComponent({
	name: 'common-checkbox',
	designLink: 'https://app.zeplin.io/project/591460ff7793c56928756817/screen/5976f4043e4198ac937d4206',
	description: 'Simple rectangle box with click area',
	props: {
		model: {
			type: 'boolean',
			description: 'Model',
			defaultValue: false,
		},
		isDisabled: {
			type: 'boolean',
			description: 'Controls disabling',
			defaultValue: false,
		},
		label: {
			type: 'string',
			description: 'Labels text (text)',
			defaultValue: 'Label',
		},
		htmlLabel: {
			type: 'string',
			description: 'Labels text (HTML)',
			defaultValue: '<span class="html-label">Label</span>',
		},
		labelClasses: {
			type: 'string',
			description: 'CSS classes for label',
			defaultValue: '',
		},
		cssClasses: {
			type: 'string',
			description: 'CSS classes for control',
			defaultValue: 'b-checkbox--dark g-textoverflow',
		},
		name: {
			type: 'string',
			description: 'Name in form',
			defaultValue: 'Name',
		},
		checkboxId: {
			type: 'string',
			description: 'Id for linkng with label',
			defaultValue: '',
		},
		onChange: {
			type: 'function',
			description: 'Callback after model changed',
			defaultValue: null,
		},
		indeterminate: {
			type: 'boolean',
			description: 'Indeterminate view mode',
			defaultValue: false,
		},
		isDescriptionShow: {
			type: 'boolean',
			description: 'Showing description',
			defaultValue: false,
		},
		descriptionValue: {
			type: 'string',
			description: 'Description text',
			defaultValue: 'Description',
		},
	},
	states: {
		isFocused: {
			type: 'boolean',
			description: 'Control is focused',
		},
		isHovered: {
			type: 'boolean',
			description: 'Cursor is over control',
		},
		isValid: {
			type: 'boolean',
			description: 'Control is valid',
		},
		isEmpty: {
			type: 'boolean',
			description: 'Control is empty',
		},
	},
})
@Component({
	selector: 'common-checkbox',
	templateUrl: './checkbox.component.pug',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./checkbox.component.sass'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => CommonCheckboxComponent),
			multi: true,
		},
	],
	encapsulation: ViewEncapsulation.None,
})
export class CommonCheckboxComponent extends CommonControlComponent<boolean> {
	@Input() label: string;
	@Input() htmlLabel: string;
	@Input() labelClasses: string;
	@Input() checkboxId: string;
	@Input() indeterminate: boolean;
	@Input() isDescriptionShow: boolean;
	@Input() descriptionValue: string;
	@Input() color: string; // hex
	@Input() isDraggable: boolean = false;

	idKey = 'checkbox_';
	isIndeterminate = false;

	ngOnChanges(changes: SimpleChanges) {
		super.ngOnChanges(changes);

		this.checkIndeterminate();
	}

	// With zonejs
	onChangeCheckbox(value: boolean) {
		const oldValue = this.model;
		this.model = value;

		if (oldValue !== value) {
			this.checkIndeterminate();
			this.modelChange.emit(value);
			this.emitOnChange(value);
		}
	}

	private checkIndeterminate() {
		this.isIndeterminate = !this.model && !!this.indeterminate;
	}
}
