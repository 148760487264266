import { NgModule } from '@angular/core';

import { CommonTextfieldModule } from '../../../Common/controls/textfield/textfield.module';
import { CommonCheckboxModule } from '../../../Common/controls/checkbox/checkbox.module';
import { CommonAutofocusModule } from '../../../Common/directives/common_autofocus/common_autofocus.module';
import { CommonPageTitleModule } from '../../../Common/page-title/page-title.module';
import { CommonAppNameModule } from '../../../Common/app-name/common-app-name.module';
import { CommonBooleanCheckboxModule } from '../../../Common/controls/boolean_checkbox/boolean_checkbox.module';


@NgModule({
	exports: [
		CommonTextfieldModule,
		CommonCheckboxModule,
		CommonBooleanCheckboxModule,
		CommonAutofocusModule,
		CommonPageTitleModule,
		CommonAppNameModule,
	],
})
export class AuthenticationCommonModule {}
