import { Directive, ElementRef, Input } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { CommonBaseComponent } from '@CaseOne/Common/base_component/base.component';
import { fromEvent, Observable, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

@Directive({
	selector: '[commonTogglePassword]',
})
export class CommonTogglePasswordDirective extends CommonBaseComponent {
	@Input() set commonTogglePassword(enable: boolean) {
		if (enable) {
			const parent = this.elemRef.nativeElement.parentNode;
			this.spanElement = this.document.createElement('span');
			this.spanElement.setAttribute('class', 'b-textfield-toggle-icon b-textfield-toggle-icon--eye');
			this.clickOnEyeIcon$ = fromEvent(this.spanElement, 'click');
			parent.appendChild(this.spanElement);
		}
	}

	private document = this.injector.get(DOCUMENT);
	private elemRef: ElementRef<HTMLElement> = this.injector.get(ElementRef);
	private shown: boolean = false;
	private spanElement: HTMLElement;
	private destroy$ = new Subject<void>();
	private clickOnEyeIcon$: Observable<Event> = new Observable<Event>();

	ngOnInit() {
		super.ngOnInit();
		this.clickOnEyeIcon$
			.pipe(
				takeUntil(this.destroy$),
				tap(() => this.toggle()),
			)
			.subscribe();
	}

	private toggle(): void {
		this.shown = !this.shown;

		if (this.shown) {
			this.elemRef.nativeElement.setAttribute('type', 'text');
			this.spanElement.setAttribute('class', 'b-textfield-toggle-icon b-textfield-toggle-icon--eye-off');
		} else {
			this.elemRef.nativeElement.setAttribute('type', 'password');
			this.spanElement.setAttribute('class', 'b-textfield-toggle-icon b-textfield-toggle-icon--eye');
		}
	}

	ngOnDestroy() {
		super.ngOnDestroy();
		this.destroy$.next();
	}
}
