import {
	NgModule,
} from '@angular/core';
import { CommonHiddenAutofillComponent } from './common-hidden-autofill.component/common-hidden-autofill.component';

@NgModule({
	declarations: [
		CommonHiddenAutofillComponent,
	],
	exports: [
		CommonHiddenAutofillComponent,
	],
	entryComponents: [
		CommonHiddenAutofillComponent,
	],
})
export class CommonHiddenAutofillModule {}
