import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ICommonResponse } from '../../../../../Common/interfaces/response';

export const APP_PROFILE_API_URL = {
	SAVE_PROFILE: 'api/UserManagment/UserProfiles/Put',
	CONFIRM_PHONE: 'api/UserManagment/UserProfiles/ConfirmPhone',
	CHANGE_PASSWORD: 'account/ChangePassword',
	RESEND_PHONE_CONFIRMATION_CODE: 'api/UserManagment/UserProfiles/ResendPhoneConfirmationCode',
	LEDES_TIMEKEEPER_CLASSIFICATION: 'api/LEDES/GetTimekeeperClassification',
};

@Injectable({
	providedIn: 'root',
})
export class AppProfileResource {
	constructor(
		private http: HttpClient,
	) {}

	update (data: any): Observable<ICommonResponse<any>> {
		return this.http.put(APP_PROFILE_API_URL.SAVE_PROFILE, data) as Observable<ICommonResponse<any>>;
	}

	changePassword (data: any): Observable<ICommonResponse<any>> {
		return this.http.post(APP_PROFILE_API_URL.CHANGE_PASSWORD, data) as Observable<ICommonResponse<any>>;
	}

	confirmPhone (data: any): Observable<ICommonResponse<any>> {
		return this.http.post(APP_PROFILE_API_URL.CONFIRM_PHONE, data) as Observable<ICommonResponse<any>>;
	}

	resendPhoneConfirmationCode (data: any): Observable<ICommonResponse<any>> {
		return this.http.post(APP_PROFILE_API_URL.RESEND_PHONE_CONFIRMATION_CODE, data) as Observable<ICommonResponse<any>>;
	}
}
