import { CommonBooleanCheckboxComponent } from './boolean_checkbox.component';
import { NgModule } from '@angular/core';
import { CommonChooseTrueValidatorModule } from '../../control_validators/choose_true/choose_true.module';
import { CommonValidationModule } from '../control/validation/validation.module';
import { CommonCheckboxModule } from '../checkbox/checkbox.module';
import { CommonModule } from '@angular/common';

@NgModule({
	imports: [
		CommonModule,
		CommonChooseTrueValidatorModule,
		CommonValidationModule,
		CommonCheckboxModule,
	],
	exports: [
		CommonBooleanCheckboxComponent,
		CommonChooseTrueValidatorModule,
		CommonCheckboxModule,
	],
	declarations: [
		CommonBooleanCheckboxComponent,
	],
	entryComponents: [
		CommonBooleanCheckboxComponent,
	],
})
export class CommonBooleanCheckboxModule {}
