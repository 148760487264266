import { Injectable } from '@angular/core';
import { CommonResource } from '@CaseOne/Common/resource/resource.class';
import { CommonResourceAction } from '@CaseOne/Common/resource/decorators/resource-action.decorator';
import { COMMON_HTTP_METHOD_TYPE } from '@CaseOne/Common/interfaces/common-http-method-type.enum';
import { ICommonResourceMethod } from '@CaseOne/Common/resource/interfaces/resource-method.interface';
import { ICommonResponse } from '@CaseOne/Common/interfaces/response';

const AGREE_WITH_TERMS_OF_PERSONAL_DATA_URL = 'api/UserProfiles/AgreeWithTermsOfPersonalData';

@Injectable()
export class AuthenticationConsentService extends CommonResource  {
	@CommonResourceAction({
		url: AGREE_WITH_TERMS_OF_PERSONAL_DATA_URL,
		method: COMMON_HTTP_METHOD_TYPE.POST,
	})

	agreeWithTermsOfPersonalData: ICommonResourceMethod<null, ICommonResponse<void>>;
}
