import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { COMMON_SYSTEM_PAGE_RELATIVE_PATHS } from '@CaseOne/Common/constants/common_system_page_relative_paths.constant';
import { CommonAppDataService } from '@CaseOne/Common/app_data/app_data.service';
import { CommonUserDataService } from '@CaseOne/Common/user/user_data.service';
import { CommonPopupService } from '@CaseOne/Common/popup/services/popup.service';
import { AppProfileConfirmPhoneDialogComponent } from '@CaseOne/App/src/profile/shared/confirm-phone-dialog/components/confirm-phone-dialog/app-profile-confirm-phone-dialog.component';
import { AppSaveChangesService } from '../../../../app-save-changes/app-save-changes.service';
import { CommonWebSocketConnection } from '@CaseOne/Common/web-socket-connection.service/web-socket-connection.service';

@Injectable({
	providedIn: 'root',
})
export class AppProfileService {
	protected appSaveChangesService = this.injector.get(AppSaveChangesService);
	protected commonWebSocketConnection = this.injector.get(CommonWebSocketConnection);
	protected commonAppDataService = this.injector.get(CommonAppDataService);
	protected commonUserDataService = this.injector.get(CommonUserDataService);
	protected popupService = this.injector.get(CommonPopupService);
	protected http = this.injector.get(HttpClient);

	constructor(
		protected injector: Injector,
	) {}

	logoff = () => {
		this.appSaveChangesService.callTrusted(() => {
			try {
				this.commonWebSocketConnection.stop();
			} catch (error) {
				console.warn('CommonWebSocketConnection did not stop', error);
			}

			const appData = this.commonAppDataService.getData();

			this.http.post(appData.appFullLocationUrl + 'Authentication/Account/Logoff', null)
				.subscribe((response: any) => {
					if (response && response.Succeeded) {
						this.goToLoginPage();
					}
				});
		});
	}

	goToLoginPage () {
		const appData = this.commonAppDataService.getData();
		const returnUrl = window.location.href;
		const redirectUrl = `${appData.appFullLocationUrl}${COMMON_SYSTEM_PAGE_RELATIVE_PATHS.LOGIN}?ReturnUrl=${encodeURIComponent(returnUrl)}`;

		window.location.assign(redirectUrl);
	}

	showConfirmationPhoneDialog (options: any = {}) {
		const popup = this.popupService.open({
			component: AppProfileConfirmPhoneDialogComponent,
			showHeader: false,
			popupClasses: 'b-popup--new_design b-popup-dialog',
		});
		const popupOnEventSubscribe$ = popup.onAfterClose()
			.subscribe(() => {
				if (options.callback) {
					options.callback();
				}

				popupOnEventSubscribe$.unsubscribe();
			});

		return popup;
	}

	isTwoFactorAuthByPhoneActive (): boolean {
		return this.commonUserDataService.getData().IsTwoFactorAuthByPhoneActive;
	}
}
