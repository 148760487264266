import { Component, Injector } from '@angular/core';
import { CommonBaseComponent } from '../../../../../../../Common/base_component/base.component';
import { CommonAppDataService } from '../../../../../../../Common/app_data/app_data.service';
import { CommonUserDataService } from '../../../../../../../Common/user/user_data.service';
import { commonConstants } from '../../../../../../../Common/constants/common.constants';
import { CommonServerDateService } from '../../../../../../../Common/server_date/server_date.service';
import { CommonLocaleService } from '../../../../../../../Common/locale/locale.service';
import { CommonSecondsFormatterPipe } from '../../../../../../../Common/pipes/seconds_formatter.pipe';
import { AppProfileResource } from '../../../services/app-profile.resource';
import { CommonPopup } from '../../../../../../../Common/popup/popup';

@Component({
	templateUrl: './app-profile-confirm-phone-dialog.component.pug',
})
export class AppProfileConfirmPhoneDialogComponent extends CommonBaseComponent {
	state;
	data;
	stopResendTimer;
	validationErrors = {
		code: '',
	};

	protected commonUserDataService = this.injector.get(CommonUserDataService);
	protected commonAppDataService = this.injector.get(CommonAppDataService);
	protected commonServerDateService = this.injector.get(CommonServerDateService);
	protected commonLocaleService = this.injector.get(CommonLocaleService);
	protected commonSecondsFormatterPipe = this.injector.get(CommonSecondsFormatterPipe);
	protected appProfileResource = this.injector.get(AppProfileResource);

	constructor(protected injector: Injector) {
		super(injector);

		const appData = this.commonAppDataService.getData();

		this.state = {
			isSaving: false,
			appName: appData.appName,
			phoneNumber: this.commonUserDataService.getData().PendingPhone,
			autofocusDelay: commonConstants.animationDuration,
			resendDelaySeconds: this.getResendDelaySeconds(),
			waitResendText: null,
		};

		this.data = {
			Code: null,
			UserId: this.commonUserDataService.getData().Id,
		};

		this.initResendTimer();
	}

	sendCode (form) {
		if (form.valid) {
			this.state.isSaving = true;

			this.appProfileResource
				.confirmPhone(this.data)
				.toPromise()
				.then((response) => {
					if (response.IsSuccess) {
						this.closePopup(true);
					} else {
						this.validationErrors = {
							code: response.Error,
						};
					}

					form.controls.Code.setErrors(response.IsSuccess ? null : { code: true });
				})
				.finally(() => {
					this.state.isSaving = false;
				});
		}
	}

	resend () {
		this.state.isSaving = true;

		this.appProfileResource
			.resendPhoneConfirmationCode({
				UserId: this.data.UserId,
			})
			.toPromise()
			.then((response) => {
				this.commonUserDataService.setDataByPath(response.Result.PendingPhone, 'PendingPhone');
				this.commonUserDataService.setDataByPath(response.Result.NextPhoneConfirmationAttemptTime, 'NextPhoneConfirmationAttemptTime');
				this.initResendTimer();
			})
			.finally(() => {
				this.state.isSaving = false;
			});
	}

	closePopup (isSuccess: boolean) {
		if (isSuccess) {
			this.commonUserDataService.setDataByPath(this.state.phoneNumber, 'Phone');
		}

		const popup = this.injector.get(CommonPopup);

		if (popup) {
			popup.close(this.state.phoneNumber);
		}
	}

	ngOnDestroy () {
		super.ngOnDestroy();

		clearInterval(this.stopResendTimer);
	}

	protected getResendDelaySeconds () {
		const NextPhoneConfirmationAttemptTime = this.commonUserDataService.getData().NextPhoneConfirmationAttemptTime;

		if (NextPhoneConfirmationAttemptTime) {
			const date = this.commonServerDateService.getMoment(NextPhoneConfirmationAttemptTime);

			return Math.abs(this.commonServerDateService.getMoment().diff(date, 'seconds') || 0);
		} else {
			return 0;
		}
	}

	protected initResendTimer () {
		this.state.resendDelaySeconds = this.getResendDelaySeconds();
		this.setWaitResendText();

		this.stopResendTimer = setInterval(() => {
			this.state.resendDelaySeconds--;
			this.setWaitResendText();

			if (!this.state.resendDelaySeconds) {
				clearInterval(this.stopResendTimer);
			}
			// TODO: [angular][force-lifecycle]
			this.runUpdate();
		}, 1000);
	}

	protected setWaitResendText () {
		this.state.waitResendText = this.commonLocaleService.instant(
			'profile.confirm_phone_dialog.timer_text',
			{
				time: this.commonSecondsFormatterPipe.transform(this.state.resendDelaySeconds),
			},
		);
	}
}
