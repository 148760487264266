import { Injectable } from '@angular/core';
import { extend, get } from 'lodash';

import { CommonPopup } from '../../popup/popup';
import { CommonDialogFactoryComponent } from '../components/dialog-factory.component';
import { ICommonDialogFactoryComponentData } from '../interfaces/dialog-factory-data-component.interface';
import { ICommonDialogFactoryOptions } from '../interfaces/dialog-factory-options.interface';
import { CommonDialogPopupService } from '@CaseOne/Common/dialog-factory/services/dialog-popup.service';
import { CommonBaseService } from '@CaseOne/Common/common-base-service.class/common-base-service.class';


const DIALOG_POPUP_CLASSES = 'b-popup--new_design b-popup-dialog';


@Injectable()
export class CommonDialogFactoryService extends CommonBaseService {
	private commonDialogPopupService = this.injector.get(CommonDialogPopupService);
	private dialog: CommonPopup;

	public create(config: Partial<ICommonDialogFactoryOptions>): CommonPopup {
		const data: ICommonDialogFactoryComponentData = extend({
			header: '',
			text: '',
			acceptText: '',
			declineText: '',
			closeText: '',
			acceptFunc: this.defaultDialogAction,
			isShowAcceptBtn: true,
			declineFunc: this.defaultDialogAction,
			closeFunc: this.defaultDialogAction,
		}, config);

		this.dialog = this.commonDialogPopupService.open({
			component: CommonDialogFactoryComponent,
			data,
			popupClasses: get(config, 'popupClasses', DIALOG_POPUP_CLASSES),
			popupOverlayClasses: get(config, 'popupOverlayClasses', ''),
			showHeader: false,
		});

		const onAfterCloseSubscribe$ = this.dialog.onAfterClose()
			.subscribe(() => {
				if (config.onClose) {
					config.onClose();
				}

				onAfterCloseSubscribe$.unsubscribe();
			});

		return this.dialog;
	}

	private defaultDialogAction = () => {
		this.dialog.close();
	}
}
