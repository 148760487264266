import { NgModule } from '@angular/core';
import { AppProfileConfirmPhoneDialogComponent } from '@CaseOne/App/src/profile/shared/confirm-phone-dialog/components/confirm-phone-dialog/app-profile-confirm-phone-dialog.component';
import { CommonFormModule } from '@CaseOne/Common/form/common-form.module';
import { CommonModule } from '@angular/common';
import { CommonTextfieldModule } from '@CaseOne/Common/controls/textfield/textfield.module';
import { CommonLocaleModule } from '@CaseOne/Common/locale/locale.module';
import { CommonLoaderModule } from '@CaseOne/Common/loader/loader.module';
import { CommonAutofocusModule } from '@CaseOne/Common/directives/common_autofocus/common_autofocus.module';

@NgModule({
	imports: [
		CommonModule,
		CommonFormModule,
		CommonLocaleModule,
		CommonTextfieldModule,
		CommonAutofocusModule,
		CommonLoaderModule,
	],
	declarations: [
		AppProfileConfirmPhoneDialogComponent,
	],
	exports: [
		AppProfileConfirmPhoneDialogComponent,
	],
})
export class AppProfileConfirmPhoneDialogModule {}
