// New version src/Common/controls/boolean_checkbox/boolean_checkbox.component.ts CommonBooleanCheckboxComponent
// Old version src/CaseDotStar.ServicePackages.Frontend.Common/scripts/common/controls/common_boolean_checkbox_ctrl_directive.js commonBooleanCheckboxCtrl

import {
	ChangeDetectionStrategy,
	Component,
	forwardRef,
	HostBinding,
	Input,
	SkipSelf,
	ViewEncapsulation,
} from '@angular/core';
import { CommonDebugComponent } from '../control/debug/debug.decorator';
import { CommonControlComponent } from '../control/control.class';
import { ControlContainer, NG_VALUE_ACCESSOR } from '@angular/forms';

@CommonDebugComponent({
	name: 'common-boolean-checkbox',
	designLink: 'https://app.zeplin.io/project/591460ff7793c56928756817/screen/5976f4043e4198ac937d4206',
	description: 'Checkbox control for forms',
	testsFile: require('!raw-loader!./boolean_checkbox.tests.spec.ts'),
	props: {
		model: {
			type: 'boolean',
			description: 'Model',
			defaultValue: false,
		},
		isDisabled: {
			type: 'boolean',
			description: 'Controls disabling',
			defaultValue: false,
		},
		label: {
			type: 'string',
			description: 'Labels text (text)',
			defaultValue: 'Label',
		},
		htmlLabel: {
			type: 'string',
			description: 'Labels text (HTML)',
			defaultValue: 'Label',
		},
		labelClasses: {
			type: 'string',
			description: 'CSS classes for label',
			defaultValue: '',
		},
		cssClasses: {
			type: 'string',
			description: 'CSS classes for control',
			defaultValue: 'b-checkbox--dark g-textoverflow',
		},
		name: {
			type: 'string',
			description: 'Name in form',
			defaultValue: 'Name',
		},
		checkboxId: {
			type: 'string',
			description: 'Id for linkng with label',
			defaultValue: '',
		},
		onChange: {
			type: 'function',
			description: 'Callback after model changed',
			defaultValue: null,
		},
		indeterminate: {
			type: 'boolean',
			description: 'Indeterminate view mode',
			defaultValue: false,
		},
	},
	states: {
		isFocused: {
			type: 'boolean',
			description: 'Control is focused',
		},
		isHovered: {
			type: 'boolean',
			description: 'Cursor is over control',
		},
		isValid: {
			type: 'boolean',
			description: 'Control is valid',
		},
		isEmpty: {
			type: 'boolean',
			description: 'Control is empty',
		},
	},
})
@Component({
	selector: 'common-boolean-checkbox',
	templateUrl: './boolean_checkbox.component.pug',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./boolean_checkbox.component.sass'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => CommonBooleanCheckboxComponent),
			multi: true,
		},
	],
	viewProviders: [
		{
			provide: ControlContainer,
			useFactory: (container: ControlContainer) => container,
			deps: [[new SkipSelf(), ControlContainer]],
		},
	],
	encapsulation: ViewEncapsulation.None,
})
export class CommonBooleanCheckboxComponent extends CommonControlComponent<boolean> {
	@Input() label: string;
	@Input() htmlLabel: string;
	@Input() labelClasses: string;
	@Input() checkboxId: string;
	@Input() indeterminate: boolean;
	@Input() ctrlCheckboxClasses: string;
	@Input() required: boolean = false;

	@HostBinding('attr.tabindex') @Input() get formTabIndex(): number {
		return null;
	}

	protected idKey = 'boolean_checkbox_';

	onChangeCheckbox(value: boolean) {
		const oldValue = this.model;
		this.model = value;

		if (oldValue !== value) {
			this.modelChange.emit(value);
			this.emitOnChange(value);
		}
	}

	writeValue(value): void {
		const normalizeValue = !!value; // to boolean

		super.writeValue(normalizeValue);

		if (normalizeValue !== value) {
			// Wait register change subscribers in the CommonCustomReactiveFormService#updateReactiveForm
			setTimeout(() => {
				(this.ngModelControl || this.formControlDirective).control.setValue(this.model, { emitModelToViewChange: false });
			});
		}
	}
}
