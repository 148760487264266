import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CommonButtonComponent } from './button.component';
import { CommonLoaderModule } from '@CaseOne/Common/loader/loader.module';

@NgModule({
	declarations: [
		CommonButtonComponent,
	],
	imports: [
		CommonModule,
		CommonLoaderModule,
	],
	exports: [
		CommonButtonComponent,
	],
})
export class CommonButtonModule {}
