import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonBaseComponent } from '../base_component/base.component';
import { get } from 'lodash';
import { COMMON_KEYBOARD_KEY } from '../constants/keyboard.constant';

@Component({
	selector: 'common-button',
	templateUrl: './button.component.pug',
	styleUrls: ['./button.component.sass'],
})
export class CommonButtonComponent extends CommonBaseComponent {
	@Input() isDisabled: boolean;
	@Input() isLoading: boolean;
	@Input() type: string = 'button';
	@Input() cssClasses: string = 'b-button--blue';
	@Input() text: string = '';
	@Input() placeholder: string = '';
	@Input() color: string = '';

	@Output() action = new EventEmitter<Event>();

	onClickOrKeydownHandler($event) {
		if (get($event, 'key') === COMMON_KEYBOARD_KEY.SPACE) {
			$event.preventDefault();
		}

		this.action.emit($event);
	}
}
