import { DOCUMENT } from '@angular/common';
import { Injectable, Injector } from '@angular/core';
import { getTempId } from '../../utilities/core.service';
import { BroadcastChannel } from 'broadcast-channel';
import { ICommonEventPayload } from '@CaseOne/Common/events/interfaces/event-payload.interface';
import { CommonEventsService } from '@CaseOne/Common/events/services/events.service';
import { iAmLeader$, startCheckingLeader } from '@CaseOne/Common/utilities/leadershipper';


@Injectable({
	providedIn: 'root',
})
export class CommonWindowService {
	iAmLeader$ = iAmLeader$;
	iAmLeader: boolean;

	private window: Window = this.injector.get(DOCUMENT).defaultView;
	private windowId: string;
	private channel = new BroadcastChannel<ICommonEventPayload>('commonCaseOneChannel');

	constructor (
		private injector: Injector,
		private commonEventsService: CommonEventsService,
	) {
		this.channel.addEventListener('message', (event: ICommonEventPayload) => {
			this.commonEventsService.emit(event);
		});

		this.iAmLeader$.subscribe((iAmLeader) => {
			this.iAmLeader = iAmLeader;
		});
	}

	getWindowId(): string {
		if (!this.windowId) {
			this.createWindowId();
		}

		return this.windowId;
	}

	openWindow(url: string, openInNewWindow = true) {
		if (openInNewWindow) {
			this.window.open(url, '_blank');
		} else {
			this.window.location.href = url;
		}
	}

	openWindowWithCheckId(url: string, id: string, openInNewWindow = true) {
		if (id === this.windowId) {
			this.openWindow(url, openInNewWindow);
		}
	}

	postMessage(msg) {
		this.channel.postMessage(msg);
	}

	startCheckingLeader() {
		startCheckingLeader();
	}

	private createWindowId() {
		this.windowId = getTempId();
	}
}
