<div class="{{ cssClasses }}" [ngClass]="{
		&quot;b-control&quot;: true,
		&quot;b-boolean_checkbox&quot;: true,
		&quot;is-disabled&quot;: isDisabled,
		&quot;ng-invalid-b_control&quot;: !isValid,

		&quot;b-common_control&quot;: true,
		&quot;b-common_control--empty&quot;: isEmpty,
		&quot;b-common_control--hovered&quot;: isHovered,
		&quot;b-common_control--focused&quot;: isFocused,
		&quot;b-common_control--pressed&quot;: isPressed,
		&quot;b-common_control--disabled&quot;: isDisabled,
		&quot;b-common_control--readonly&quot;: isReadonly,
		&quot;b-common_control--invalid&quot;: !isValid,
		&quot;b-common_control--highlighted&quot;: (isHighlighted$ | async),
		&quot;b-common_control--bg_transition_enabled&quot;: (isBgTransitionEnabled$ | async),

		&quot;b-common_boolean_checkbox&quot;: true
	}" (mouseenter)="onMouseEnterHandler()" (mouseleave)="onMouseLeaveHandler()" (mousedown)="onMouseDownHandler()" (mouseup)="onMouseUpHandler()"><common-checkbox [ngModel]="model" [ngModelOptions]="{ standalone: true }" [commonChooseTrueValidatorDirective]="required" [isDisabled]="isDisabled" [name]="'common-checkbox_' + name" [label]="label" [htmlLabel]="htmlLabel" [labelClasses]="labelClasses" [checkboxId]="checkboxId" [cssClasses]="ctrlCheckboxClasses" [indeterminate]="indeterminate" (onChange)="onChangeCheckbox($event)" (onFocus)="onFocusHandler($event)" (onBlur)="onBlurHandler($event)" [enabledValidationMessages]="false" [customErrors]="customErrors"></common-checkbox><common-validation [isShow]="isShowValidation" [ngModelControl]="ngModelControl" [formControlDirective]="formControlDirective" [formGroupDirective]="formGroupDirective" [customErrors]="customErrors"></common-validation></div>