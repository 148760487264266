import { Injectable } from '@angular/core';
import { CommonEventsService } from '../events/services/events.service';
import { ICommonEventPayload } from '../events/interfaces/event-payload.interface';

export enum COMMON_PAGE_VISIBILITY_EVENT {
	CHANGE_VISIBILITY = 'CHANGE_VISIBILITY',
}

@Injectable({
	providedIn: 'root',
})
export class CommonPageVisibilityService extends CommonEventsService<boolean> {
	isVisible: boolean;

	private readonly hiddenPropName: string;
	private readonly visibilityChangeEventName: string;

	constructor() {
		super();

		// Set the name of the hidden property and the change event for visibility
		if (typeof document.hidden !== 'undefined') { // Opera 12.10 and Firefox 18 and later support
			this.hiddenPropName = 'hidden';
			this.visibilityChangeEventName = 'visibilitychange';
		} else {
			// @ts-ignore
			if (typeof document.msHidden !== 'undefined') {
				this.hiddenPropName = 'msHidden';
				this.visibilityChangeEventName = 'msvisibilitychange';
			// @ts-ignore
			} else if (typeof document.webkitHidden !== 'undefined') {
				this.hiddenPropName = 'webkitHidden';
				this.visibilityChangeEventName = 'webkitvisibilitychange';
			}
		}

		if (typeof document.addEventListener !== 'undefined' && this.hiddenPropName) {
			this.checkPageVisible();

			// Handle page visibility change
			document.addEventListener(this.visibilityChangeEventName, this.handleVisibilityChange.bind(this), false);
		}
	}

	private checkPageVisible () {
		const isVisible = !document[this.hiddenPropName];

		if (this.isVisible !== isVisible) {
			this.isVisible = isVisible;
			this.emit({
				name: COMMON_PAGE_VISIBILITY_EVENT.CHANGE_VISIBILITY,
				payload: isVisible,
			});
		}
	}

	private handleVisibilityChange () {
		this.checkPageVisible();
	}
}
