import { NgModule } from '@angular/core';
import {
	CommonTogglePasswordDirective
} from '@CaseOne/Common/directives/common-toggle-password/common-toggle-password.directive';

@NgModule({
	declarations: [
		CommonTogglePasswordDirective,
	],
	exports: [
		CommonTogglePasswordDirective,
	],
})
export class CommonTogglePasswordModule { }
