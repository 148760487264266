import { NgModule } from '@angular/core';
import { AppProfileService } from '@CaseOne/App/src/profile/shared/profile-service/services/app-profile.service';
import { AppProfileConfirmPhoneDialogModule } from '@CaseOne/App/src/profile/shared/confirm-phone-dialog/confirm-phone-dialog.module';
import { AppSaveChangesModule } from '@CaseOne/App/src/app-save-changes/app-save-changes.module';
import { CommonPopupModule } from '@CaseOne/Common/popup/popup.module';

@NgModule({
	imports: [
		CommonPopupModule,
		AppSaveChangesModule,
		AppProfileConfirmPhoneDialogModule,
	],
	providers: [
		AppProfileService,
	],
})
export class AppProfileServiceModule {}
