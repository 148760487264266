import {
	NgModule,
} from '@angular/core';
import { CommonLoaderModule } from '../loader/loader.module';
import { CommonHiddenAutofillModule } from '../fixer/hidden-autofill/common-hidden-autofill.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonFormSubmittersComponent } from './common-form-submitters.component/common-form-submitters.component';
import { CommonButtonModule } from '../button/button.module';
import { CommonModule } from '@angular/common';
import { CommonFormHiddenSubmitComponent } from './common-form-hidden-submit.component/common-form-hidden-submit.component';

@NgModule({
	declarations: [
		CommonFormSubmittersComponent,
		CommonFormHiddenSubmitComponent,
	],
	imports: [
		ReactiveFormsModule,
		FormsModule,
		CommonModule,
		CommonLoaderModule,
		CommonHiddenAutofillModule,
		CommonButtonModule,
	],
	exports: [
		ReactiveFormsModule,
		FormsModule,
		CommonModule,
		CommonFormSubmittersComponent,
		CommonFormHiddenSubmitComponent,
		CommonLoaderModule,
		CommonHiddenAutofillModule,
		CommonButtonModule,
	],
})
export class CommonFormModule {}
